import React, { useState, useEffect } from 'react';
import PlacesAutocomplete, { geocodeByAddress, getLatLng } from 'react-places-autocomplete';
import { useGoogleMaps } from './GoogleMapsContext';

const NPPlacesInput = ({ value, onResultSelected, placeholder }) => {
    const { isLoaded, loadError } = useGoogleMaps();
    const [localAddress, setLocalAddress] = useState(value);

    useEffect(() => {
        const timeoutId = setTimeout(() => {
            if (localAddress !== value) {
                geocodeByAddress(localAddress)
                    .then(results => getLatLng(results[0]))
                    .then(latlng => {
                        onResultSelected(localAddress, latlng);
                    })
                    .catch(error => {
                        console.error('Error getting latLng', error);
                    });
            }
        }, 500);

        return () => clearTimeout(timeoutId);
    }, [localAddress]);

    if (loadError) return <div>Error loading maps</div>;
    if (!isLoaded) return <div>Loading...</div>;

    return (
        <PlacesAutocomplete
            value={localAddress}
            onChange={setLocalAddress}
            onSelect={(address) => {
                setLocalAddress(address);
                geocodeByAddress(address)
                    .then(results => getLatLng(results[0]))
                    .then(latlng => {
                        onResultSelected(address, latlng);
                    })
                    .catch(error => {
                        console.error('Error getting latLng', error);
                    });
            }}
            searchOptions={{ componentRestrictions: { country: "CA" } }}
        >
            {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
                <div className="autocomplete">
                    <input
                        {...getInputProps({
                            placeholder,
                            className: "form-control",
                        })}
                    />
                    <div className="autocomplete-items">
                        {loading && <div>Loading...</div>}
                        {suggestions.map((suggestion, index) => {
                            const style = suggestion.active
                                ? { backgroundColor: '#fafafa', cursor: 'pointer' }
                                : { backgroundColor: '#ffffff', cursor: 'pointer' };
                            return (
                                <div
                                    {...getSuggestionItemProps(suggestion, { style })}
                                    key={index}
                                >
                                    {suggestion.description}
                                </div>
                            );
                        })}
                    </div>
                </div>
            )}
        </PlacesAutocomplete>
    );
};

export default NPPlacesInput;
