import React, {useState, useEffect, useContext} from "react";
import axios from "axios";
import withAuth from "../../auth";
import Pagination from "../Pagination";
import moment from 'moment';
import { API_URL } from '../../constants';
import {Link, useNavigate} from "react-router-dom";
import {Breadcrumb} from "react-bootstrap";
import {AuthContext} from "../AuthContext";

const PickupList = () => {
    const [pickups, setPickups] = useState([]);
    const [loading, setLoading] = useState(true);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const [sortBy, setSortBy] = useState('id');
    const [sortOrder, setSortOrder] = useState('desc');
    const [filters, setFilters] = useState({
        // id: '',
        // user_id,
        // truck_type_id,
        // claim_id,
        // driver_id,
        // delivery_status_id,
        // created_at_start_date: '',
        // created_at_end_date: '',
    });
    const navigate = useNavigate();
    const {token, setToken, hasPermission} = useContext(AuthContext);

    useEffect(() => {
        fetchPickups();
    }, [currentPage, sortBy, sortOrder]);

    const fetchPickups = async (newFilters = null) => {
        setLoading(true);
        try {
            const response = await axios.post(`${API_URL}/api/admin/pickups`,
                {
                    sort_by: sortBy,
                    sort_order: sortOrder,
                    page: currentPage,
                    ...(newFilters || filters),
                },
                {
                    headers: {
                        'Authorization': `Bearer ${token}`,
                    }
                }
            );
            setPickups(response.data.data);
            setTotalPages(response.data.last_page);
        } catch (error) {
            console.error("There was an error fetching the drivers!", error);
            if (error.response && error.response.status === 401) {
                localStorage.removeItem('authToken');
                setToken(null);
                navigate('/');
            }
        } finally {
            setLoading(false);
        }
    };

    const handleSortChange = (column) => {
        if (sortBy === column) {
            setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc');
        } else {
            setSortBy(column);
            setSortOrder('asc');
        }
        setCurrentPage(1); // reset pagination when sort
    };

    const handlePageChange = (page) => {
        setCurrentPage(page);
    };

    const handleFilterChange = (e, column) => {
        const newFilters = {
            ...filters,
            [column]: e.target.value,
        };

        const nonEmptyFilters = Object.keys(newFilters).reduce((acc, key) => {
            if (newFilters[key]) {
                acc[key] = newFilters[key];
            }
            return acc;
        }, {});

        setFilters(nonEmptyFilters);
    };

    const handleSearchSubmit = () => {
        setCurrentPage(1); // reset pagination when filter
        fetchPickups();
    };

    const handleDropdownChange = (e, column) => {
        const newFilters = {
            ...filters,
            [column]: e.target.value,
        };

        const nonEmptyFilters = Object.keys(newFilters).reduce((acc, key) => {
            if (newFilters[key]) {
                acc[key] = newFilters[key];
            }
            return acc;
        }, {});

        setFilters(nonEmptyFilters);
        setCurrentPage(1); // reset pagination when filter
        fetchPickups(nonEmptyFilters);
    };

    const statusColors = {
        '0': 'transparent',
        '1': '#FFFAE5',  // Very Light Gold
        '2': '#FFCBB2',  // Very Light OrangeRed
        '3': '#CFF3C8',  // Very Light Green
        '4': '#C7D6E9',  // Very Light SteelBlue
        '5': '#D1F2CF',  // Very Light LimeGreen
        '6': '#FFA3B5'   // Very Light Crimson
    };

    const truncateWithEllipsis = (text, maxLength = 30) => {
        if (text.length > maxLength) {
            return (
                <span title={text}>
                    {text.substring(0, maxLength)}...
                </span>
            );
        }
        return text;
    };

    return (
        <div className="container-fluid mt-3">
            <h2 className="text-center">Pickup List</h2>
            {loading ? (
                <div className="d-flex justify-content-center align-items-center" style={{ height: '80vh' }}>
                    <div className="spinner-border" role="status">
                        <span className="sr-only">Loading...</span>
                    </div>
                </div>
            ) : (
                <div className="table-responsive">
                    <table className="table table-sm table-striped text-center text-nowrap">
                        <thead>
                        <tr>
                            <th onClick={() => handleSortChange('id')}>
                                ID <i className={`fa fa-sort${sortBy === 'id' ? (sortOrder === 'asc' ? '-asc' : '-desc') : ''}`}/>
                            </th>
                            <th onClick={() => handleSortChange('user_id')}>
                                Customer <i className={`fa fa-sort${sortBy === 'user_id' ? (sortOrder === 'asc' ? '-asc' : '-desc') : ''}`}/>
                            </th>
                            <th onClick={() => handleSortChange('driver_id')}>
                                Driver <i className={`fa fa-sort${sortBy === 'driver_id' ? (sortOrder === 'asc' ? '-asc' : '-desc') : ''}`}/>
                            </th>
                            <th onClick={() => handleSortChange('truck_type_id')}>
                                Truck Type <i className={`fa fa-sort${sortBy === 'truck_type_id' ? (sortOrder === 'asc' ? '-asc' : '-desc') : ''}`}/>
                            </th>
                            <th onClick={() => handleSortChange('cost')}>
                                Cost / Dist. <i className={`fa fa-sort${sortBy === 'cost' ? (sortOrder === 'asc' ? '-asc' : '-desc') : ''}`}/>
                            </th>
                            <th>
                                Locations
                            </th>
                            <th onClick={() => handleSortChange('delivery_status_id')}>
                                Delivery Status <i className={`fa fa-sort${sortBy === 'delivery_status_id' ? (sortOrder === 'asc' ? '-asc' : '-desc') : ''}`}/>
                            </th>
                            <th onClick={() => handleSortChange('claim_id')}>
                                Claimed <i className={`fa fa-sort${sortBy === 'claim_id' ? (sortOrder === 'asc' ? '-asc' : '-desc') : ''}`}/>
                            </th>
                            <th onClick={() => handleSortChange('created_at')}>
                                Created At <i className={`fa fa-sort${sortBy === 'created_at' ? (sortOrder === 'asc' ? '-asc' : '-desc') : ''}`}/>
                            </th>
                        </tr>
                        <tr>
                            {/* ID */}
                            <td>
                                <div className="input-group">
                                    <input
                                        className="form-control form-control-sm text-center"
                                        type="text"
                                        inputMode="search"
                                        value={filters.id}
                                        placeholder='ID'
                                        onChange={(e) => handleFilterChange(e, 'id')}
                                        onKeyPress={e => {
                                            if (e.key === 'Enter') handleSearchSubmit(e)
                                        }}
                                    />
                                    <button className="btn btn-sm btn-outline-secondary" onClick={handleSearchSubmit}>
                                        <i className="fa fa-search" />
                                    </button>
                                </div>
                            </td>
                            {/* Customer */}
                            <td>
                                <div className="input-group">
                                    <input
                                        className="form-control form-control-sm text-center"
                                        type="text"
                                        inputMode="search"
                                        value={filters.user_id}
                                        placeholder='Customer ID'
                                        onChange={(e) => handleFilterChange(e, 'user_id')}
                                        onKeyPress={e => {
                                            if (e.key === 'Enter') handleSearchSubmit(e)
                                        }}
                                    />
                                    <button className="btn btn-sm btn-outline-secondary" onClick={handleSearchSubmit}>
                                        <i className="fa fa-search" />
                                    </button>
                                </div>
                            </td>
                            {/* Driver */}
                            <td>
                                <div className="input-group">
                                    <input
                                        className="form-control form-control-sm text-center"
                                        type="text"
                                        inputMode="search"
                                        value={filters.driver_id}
                                        placeholder='Driver ID'
                                        onChange={(e) => handleFilterChange(e, 'driver_id')}
                                        onKeyPress={e => {
                                            if (e.key === 'Enter') handleSearchSubmit(e)
                                        }}
                                    />
                                    <button className="btn btn-sm btn-outline-secondary" onClick={handleSearchSubmit}>
                                        <i className="fa fa-search" />
                                    </button>
                                </div>
                            </td>
                            {/* Truck Type */}
                            <td>
                                <div className="input-group">
                                    <select
                                        className="form-control form-control-sm text-center"
                                        value={filters.truck_type_id}
                                        onChange={(e) => handleDropdownChange(e, 'truck_type_id')}
                                    >
                                        <option value="">All</option>
                                        <option value="1">Pickup</option>
                                        <option value="2">Flatbed</option>
                                        <option value="3">Cube Van</option>
                                    </select>
                                </div>
                            </td>
                            {/* Cost / Distance */}
                            <td></td>
                            {/* Locations */}
                            <td></td>
                            {/* Delivery Status */}
                            <td>
                                <div className="input-group">
                                    <select
                                        className="form-control form-control-sm text-center"
                                        value={filters.delivery_status_id}
                                        onChange={(e) => handleDropdownChange(e, 'delivery_status_id')}
                                    >
                                        <option value="">All</option>
                                        <option value="0">Not started</option>
                                        <option value="1">Going to pickup location</option>
                                        <option value="2">At pickup location</option>
                                        <option value="3">Going to dropoff location</option>
                                        <option value="4">At dropoff location</option>
                                        <option value="5">Pickup Complete</option>
                                        <option value="6">Pickup Cancelled</option>
                                    </select>
                                </div>
                            </td>
                            {/* Claimed */}
                            <td>
                                <div className="input-group">
                                    <select
                                        className="form-control form-control-sm text-center"
                                        value={filters.claim_id}
                                        onChange={(e) => handleDropdownChange(e, 'claim_id')}
                                    >
                                        <option value="">All</option>
                                        <option value="1">Claimed</option>
                                        <option value="0">Unclaimed</option>
                                    </select>
                                </div>
                            </td>
                            {/* Created At */}
                            <td></td>
                        </tr>
                        </thead>
                        <tbody className="text-wrap">
                        {pickups.map((pickup) => (
                            <tr key={pickup.id}>
                                <td style={{ backgroundColor: !pickup.delivery_status?.id ? 'initial' : statusColors[pickup.delivery_status?.id] || 'initial' }}>
                                    <Link to={`/pickups/${pickup.id}`}>
                                        {pickup.id}
                                    </Link>
                                    {pickup.user && hasPermission('user-update') && (
                                        <>
                                            <br/>
                                            <Link to={`/pickups/create/${pickup.user.id}?duplicate=${pickup.id}`}
                                                  className='btn btn-sm btn-outline-primary'>
                                                Duplicate
                                            </Link>
                                        </>
                                    )}
                                </td>
                                <td>
                                    {pickup.user &&
                                    <Link to={`/users/${pickup.user.id}`}>
                                        {pickup.user.id}<br/>{pickup.user.full_name}
                                    </Link>
                                    }
                                </td>
                                <td>
                                    {pickup.driver &&
                                    <Link to={`/drivers/${pickup.driver.id}`}>
                                        {pickup.driver.id}<br/>{pickup.driver.name}
                                    </Link>
                                    }
                                </td>
                                <td>{pickup.truck_type?.title}</td>
                                <td>${pickup.cost}<br/>{pickup.distance} km</td>
                                <td className="text-nowrap">
                                    {truncateWithEllipsis(pickup.origin_address)}<br />
                                    {truncateWithEllipsis(pickup.dropoff_address)}
                                </td>
                                <td>{pickup.delivery_status?.id}<br/>{pickup.delivery_status?.status}</td>
                                <td>{pickup.claim?.status}</td>
                                <td>
                                    {moment(pickup.created_at).format('YYYY-MM-DD HH:mm')}
                                </td>
                            </tr>
                        ))}
                        </tbody>
                    </table>
                    {totalPages > 1 && currentPage <= totalPages &&
                        <Pagination currentPage={currentPage} totalPages={totalPages} handlePageChange={handlePageChange}/>
                    }
                </div>
            )}
        </div>
    );
};

export default withAuth(PickupList);
