import React, {useState, useEffect, useContext} from 'react';
import axios from 'axios';
import { API_URL } from '../../constants';
import {Link, useNavigate, useParams} from 'react-router-dom';
import withAuth from "../../auth";
import { Breadcrumb } from 'react-bootstrap';
import moment from "moment";
import {AuthContext} from "../AuthContext";
import CreditCardModal from "./CreditCardModal";
import Spinner from "react-bootstrap/Spinner";
import NPMapViewDirections from "../google_map/NPMapViewDirections";
import { GoogleMapsProvider } from '../google_map/GoogleMapsContext';
import {formatPhoneNumberWithMask} from "../../formatPhoneNumber";
import pubnub from "../PubNubService";

const PickupDetails = () => {
    const { pickupId } = useParams();
    const [pickup, setPickup] = useState(null);
    const [trackers, setTrackers] = useState(null);
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();
    const {token, setToken, hasPermission} = useContext(AuthContext);
    const [showModal, setShowModal] = useState(false);
    const [success, setSuccess] = useState('');
    const [error, setError] = useState('');
    const [isCancelSubmitted, setIsCancelSubmitted] = useState(false);

    const [deliveryCost, setDeliveryCost] = useState(0.0);
    const [isDeliveryCostSubmitted, setDeliveryCostSubmitted] = useState(false);

    const [promoCode, setPromoCode] = useState('');
    const [isPromoSubmitting, setIsPromoSubmitting] = useState(false);

    const statusColors = {
        '0': 'transparent',
        '1': '#FFFAE5',  // Very Light Gold
        '2': '#FFCBB2',  // Very Light OrangeRed
        '3': '#CFF3C8',  // Very Light Green
        '4': '#C7D6E9',  // Very Light SteelBlue
        '5': '#D1F2CF',  // Very Light LimeGreen
        '6': '#FFA3B5'   // Very Light Crimson
    };

    useEffect(() => {
        axios.get(`${API_URL}/api/admin/pickups/${pickupId}`,
            {
                headers: {
                    'Authorization': `Bearer ${token}`,
                }
            })
            .then(response => {
                setPickup(response.data.data);
                setTrackers(response.data.trackers);
            })
            .catch(error => {
                console.error('Error fetching the pickup details!', error);
                if (error.response && error.response.status === 401) {
                    localStorage.removeItem('authToken');
                    setToken(null);
                    navigate('/');
                }
            });
    }, [pickupId]);

    useEffect(() => {
        if (!pickup) return;

        const channel = pickup.channel_id;
        pubnub.subscribe({ channels: [channel] });

        const listener = {
            message: (event) => {
                if (event.message?.pickup?.id === pickup.id) {
                    setPickup((prevPickup) => ({
                        ...prevPickup,
                        ...event.message.pickup,
                    }));
                    setTrackers(event.message.trackers);
                }

                // some messages come like this
                if (event.message?.original?.pickup?.id === pickup.id) {
                    setPickup((prevPickup) => ({
                        ...prevPickup,
                        ...event.message?.original.pickup,
                    }));
                    setTrackers(event.message?.original.trackers);
                }
            },
        };

        pubnub.addListener(listener);

        return () => {
            pubnub.unsubscribe({ channels: [channel] });
            pubnub.removeListener(listener);
        };
    }, [pickup]);

    useEffect(() => {
        setDeliveryCost(pickup?.delivery_cost || 0.0);
    }, [pickup]);

    const handleModalShow = () => setShowModal(true);
    const handleModalClose = () => setShowModal(false);

    const handleModalSuccess = (msg, pickup) => {
        setSuccess(msg);
        setPickup(pickup);
    };

    const cancelPickup = async () => {
        if (!window.confirm("Are you sure that you want to cancel this pickup?")) return false;

        setIsCancelSubmitted(true);
        try {
            const response = await axios.post(`${API_URL}/api/admin/pickups/${pickup.id}/cancel`, {}, {
                headers: {'Authorization': `Bearer ${token}`}
            });
            setSuccess(response.data.message);
            setPickup(response.data.pickup);
        }
        catch (error) {
            if (error.response && error.response.status === 401) {
                localStorage.removeItem('authToken');
                setToken(null);
                navigate('/');
            }
            if (error?.response?.data?.errors) {
                const errors = error.response.data.errors;
                const errorMessages = Object.keys(errors).map(key => errors[key].join(' ')).join(' ');
                setError(errorMessages);
            } else if (error?.response?.data?.message) {
                setError(error.response.data.message);
            } else {
                setError('An error occurred. Please try again later.');
            }
        } finally {
            setIsCancelSubmitted(false);
        }
    };

    const saveDeliveryCost = async () => {
        if (!window.confirm("Are you sure that you want to change the delivery cost of this pickup?")) return false;

        setDeliveryCostSubmitted(true);
        try {
            const response = await axios.post(`${API_URL}/api/admin/pickups/${pickup.id}/change-cost`, {cost: deliveryCost}, {
                headers: {'Authorization': `Bearer ${token}`}
            });
            setSuccess(response.data.message);
            setPickup(response.data.pickup);
        }
        catch (error) {
            if (error.response && error.response.status === 401) {
                localStorage.removeItem('authToken');
                setToken(null);
                navigate('/');
            }
            if (error?.response?.data?.errors) {
                const errors = error.response.data.errors;
                const errorMessages = Object.keys(errors).map(key => errors[key].join(' ')).join(' ');
                setError(errorMessages);
            } else if (error?.response?.data?.message) {
                setError(error.response.data.message);
            } else {
                setError('An error occurred. Please try again later.');
            }
        } finally {
            setDeliveryCostSubmitted(false);
        }
    };

    const applyPromoCode = async () => {
        if (!window.confirm("Are you sure that you want to apply this promo code?")) return false;

        setIsPromoSubmitting(true);
        try {
            const response = await axios.post(
                `${API_URL}/api/admin/pickups/${pickup.id}/apply-promo`,
                {promo_code: promoCode},
                { headers: {'Authorization': `Bearer ${token}`}}
            );
            setSuccess(response.data.message);
            setPickup(response.data.pickup);
            setPromoCode('');
        } catch (error) {
            if (error.response && error.response.status === 401) {
                localStorage.removeItem('authToken');
                setToken(null);
                navigate('/');
            }
            if (error?.response?.data?.errors) {
                const errors = error.response.data.errors;
                const errorMessages = Object.keys(errors).map(key => errors[key].join(' ')).join(' ');
                setError(errorMessages);
            } else if (error?.response?.data?.message) {
                setError(error.response.data.message);
            } else {
                setError('An error occurred. Please try again later.');
            }
        } finally {
            setIsPromoSubmitting(false);
        }
    };

    // Function to render stars based on rating
    const renderStars = (rating) => {
        const fullStars = Math.floor(rating);
        const halfStar = rating - fullStars >= 0.5;
        const emptyStars = 5 - fullStars - (halfStar ? 1 : 0);

        return (
            <>
                {[...Array(fullStars)].map((e, i) =>
                    <i key={`full-${i}`} className="fa fa-star" style={{ color: '#FFD700' }}></i>
                )}
                {halfStar && <i className="fa fa-star-half-o" style={{ color: '#FFD700' }}></i>}
                {[...Array(emptyStars)].map((e, i) =>
                    <i key={`empty-${i}`} className="fa fa-star-o" style={{ color: '#FFD700' }}></i>
                )}
                &nbsp;({rating.toFixed(1)})
            </>
        );
    };

    return (
        <div className="container-fluid mt-3">
            <Breadcrumb>
                <Breadcrumb.Item href="/pickups">Pickups</Breadcrumb.Item>
                <Breadcrumb.Item active>Pickup Details</Breadcrumb.Item>
            </Breadcrumb>
            {pickup ? (
                <div>
                    <h1 className="text-center" style={{position: 'relative'}}>
                        Pickup Details #{pickup ? pickup.id : ''}
                        {pickup.user && hasPermission('user-update') && (
                            <div className="duplicate-pickup-wrap">
                                <Link to={`/pickups/create/${pickup.user.id}?duplicate=${pickup.id}`}
                                      className='duplicate-pickup-btn btn btn-outline-primary'>
                                    Duplicate
                                </Link>
                            </div>
                        )}
                    </h1>
                    {success && <div className="alert alert-success">{success}</div>}
                    {error && <div className="alert alert-danger">{error}</div>}
                    <div className="row">
                        <div className="col-md-6">
                            <div className="card mb-2">
                                <div className="card-body" align="center">
                                    <h6>ID: {pickup.id}</h6>
                                    <h6 className="text-center p-2" style={{ backgroundColor: !pickup.delivery_status?.id ? 'initial' : statusColors[pickup.delivery_status?.id] || 'initial' }}>
                                        Delivery Status: {pickup.delivery_status.status}
                                    </h6>
                                    {hasPermission("pickup-update") && (
                                        <>
                                            {pickup.delivery_status.id === 0 && !pickup.waiting_for_driver_at && (
                                                <>
                                                    <button className="btn btn-warning mb-2" onClick={handleModalShow}>Book on Behalf</button>
                                                    <CreditCardModal
                                                        userId={pickup?.user?.id}
                                                        pickupId={pickup?.id}
                                                        show={showModal}
                                                        handleClose={handleModalClose}
                                                        handleSuccess={handleModalSuccess}
                                                    />
                                                </>
                                            )}
                                            {pickup.delivery_status.id !== 5 && pickup.delivery_status.id !== 6 && (
                                                <>
                                                    {" "}
                                                    <button className="btn btn-danger mb-2" onClick={cancelPickup}>
                                                        {isCancelSubmitted
                                                            ? <><Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" />&nbsp;Canceling...</>
                                                            : "Cancel"
                                                        }
                                                    </button>
                                                </>
                                            )}
                                        </>
                                    )}
                                    <p>
                                        Customer: {pickup.user &&
                                        <Link to={`/users/${pickup.user.id}`}>
                                            <b>{pickup.user.id} {pickup.user.full_name}</b> ({pickup.user.email})
                                        </Link>
                                        }
                                        <br/>
                                        Driver: {pickup.driver &&
                                        <Link to={`/drivers/${pickup.driver.id}`}>
                                            <b>{pickup.driver.id} {pickup.driver.name}</b> ({pickup.driver.email})
                                        </Link>
                                        }
                                    </p>
                                    <p className="mb-0">
                                        Truck Type: <strong>{pickup.truck_type.title}</strong>
                                        {pickup.vehicle &&
                                        <span>
                                            <br/>
                                            Vehicle: <Link to={`/vehicles/${pickup.vehicle.id}`}>
                                                <b>{pickup.vehicle.id} {pickup.vehicle.make} {pickup.vehicle.model}</b>
                                            </Link>
                                        </span>}
                                    </p>
                                </div>
                            </div>
                            <ul className="list-group mb-2">
                                {pickup.additional_info && <li className="list-group-item">Additional info: <strong>{pickup.additional_info}</strong></li>}
                                {pickup.claim && <li className="list-group-item">Claim: <strong>{pickup.claim.status}</strong></li>}
                                {pickup.cancelled_by_driver && <li className="list-group-item">
                                    Canceled by driver: <Link to={`/drivers/${pickup.cancelled_by_driver.id}`}>
                                    <b>{pickup.cancelled_by_driver.id} {pickup.cancelled_by_driver.name}</b> ({pickup.cancelled_by_driver.email})
                                </Link>
                                </li>}
                            </ul>

                            <div className="card mb-2">
                                <div className="card-header"><h5 className="mb-0 text-center">Prices</h5></div>
                                <div className="card-body">
                                    <div className="table-responsive mb-4">
                                        <table className='table table-sm table-striped text-center mb-0'>
                                            <tbody>
                                            {pickup.is_promo_applied && (
                                                <>
                                                    <tr>
                                                        <td>Regular Pickup Fee (Delivery Cost)</td>
                                                        <td className="">${pickup.regular_delivery_cost}</td>
                                                    </tr>
                                                    <tr>
                                                        <td>
                                                            <Link to={`/promo-codes/${pickup.promo_code_id}`}>
                                                                Promo Discount
                                                                (
                                                                {pickup.promo_code?.discount_type === 'percentage' && <span>{pickup.promo_code?.discount_value}%</span>}
                                                                {pickup.promo_code?.discount_type === 'fixed' && <span>${pickup.promo_code?.discount_value}</span>}
                                                                )
                                                            </Link>
                                                        </td>
                                                        <td className="text-info"><b>-${pickup.discount_amount}</b></td>
                                                    </tr>
                                                </>
                                            )}
                                            <tr>
                                                {pickup.is_promo_applied
                                                    ? <td>Pickup Fee</td>
                                                    : <td>Pickup Fee (Delivery Cost)</td>
                                                }
                                                {hasPermission("pickup-update") ? (
                                                    <td>
                                                        {pickup.waiting_for_driver_at
                                                            ? <span>${pickup.delivery_cost}</span>
                                                            : <div className="input-group input-group-sm justify-content-center">
                                                                <div className="input-group-prepend">
                                                                    <span className="input-group-text">$</span>
                                                                </div>
                                                                <input type="number"
                                                                       className="form-control text-center input-no-spinners"
                                                                       placeholder="30.00"
                                                                       value={deliveryCost}
                                                                       onChange={(e) => setDeliveryCost(e.target.value)}
                                                                       style={{maxWidth: "75px", flex: '1 1 75px'}}/>
                                                                <div className="input-group-append">
                                                                    <button className="btn btn-outline-secondary" type="button" onClick={saveDeliveryCost}
                                                                            style={{width: '50px'}}>
                                                                        {isDeliveryCostSubmitted
                                                                            ? <><Spinner as="span" animation="border" size="sm"
                                                                                         role="status"
                                                                                         aria-hidden="true"/></>
                                                                            : "Save"
                                                                        }
                                                                    </button>
                                                                </div>
                                                            </div>
                                                        }
                                                    </td>
                                                ) : (
                                                    <td>${pickup.delivery_cost}</td>
                                                )}
                                            </tr>
                                            <tr>
                                                <td>Cargo Protection Pkg ({pickup.cargo_insurance.insurance_range})</td>
                                                <td>${pickup.insurance_cost}</td>
                                            </tr>
                                            <tr>
                                                <td>Cost (Pickup Fee + Cargo Protection Pkg)</td>
                                                <td><b>${pickup.cost}</b></td>
                                            </tr>
                                            <tr>
                                                <td>GST {parseFloat(pickup.tax_rate?.gst || 0)}%</td>
                                                <td>${pickup.gst_five_percent}</td>
                                            </tr>
                                            <tr>
                                                <td>PST {parseFloat(pickup.tax_rate?.pst || 0)}%</td>
                                                <td>${pickup.pst_seven_percent}</td>
                                            </tr>
                                            <tr>
                                                <td>HST {parseFloat(pickup.tax_rate?.hst || 0)}%</td>
                                                <td>${pickup.hst_amount}</td>
                                            </tr>
                                            <tr>
                                                <td>QST {parseFloat(pickup.tax_rate?.qst || 0)}%</td>
                                                <td>${pickup.qst_amount}</td>
                                            </tr>
                                            <tr>
                                                <td>Total Cost</td>
                                                <td><b>${pickup.total_cost}</b></td>
                                            </tr>
                                            </tbody>
                                        </table>
                                    </div>

                                    {/* Apply Promo Code */}
                                    {!pickup.is_promo_applied && pickup.delivery_status.id !== 5 && pickup.delivery_status.id !== 6 && !pickup.waiting_for_driver_at && (
                                        <div className="mb-4 text-center">
                                            <div className="input-group input-group-sm justify-content-center" style={{maxWidth: "300px", margin: "0 auto"}}>
                                                <input type="text"
                                                       className="form-control text-center"
                                                       placeholder="Promo Code"
                                                       value={promoCode}
                                                       onChange={(e) => setPromoCode(e.target.value)}
                                                />
                                                <div className="input-group-append">
                                                    <button className="btn btn-outline-secondary" type="button" onClick={applyPromoCode}>
                                                        {isPromoSubmitting
                                                            ? <><Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true"/></>
                                                            : "Apply"
                                                        }
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    )}

                                    <h5 className="text-center">Driver Earnings</h5>
                                    <div className="table-responsive">
                                        <table className="table table-sm table-striped text-center mb-0">
                                            <tbody>
                                            <tr>
                                                <td>
                                                    {pickup.is_promo_applied
                                                        ? "Regular Pickup Fee (Delivery Cost)"
                                                        : "Pickup Fee (Delivery Cost)"
                                                    }
                                                </td>
                                                <td className="">${pickup.regular_delivery_cost}</td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <Link to={"/truck-types/prices#truck-type-" + pickup.truck_type.id} target="_blank">
                                                        Driver Compensation Rate ({pickup.truck_type.title})
                                                    </Link>
                                                </td>
                                                <td>{pickup.truck_type.driver_percent}%</td>
                                            </tr>
                                            <tr>
                                                <td>Driver Earnings</td>
                                                <td><b>${pickup.driver_earnings}</b></td>
                                            </tr>
                                            </tbody>
                                        </table>
                                    </div>

                                    {/* Invoice Button */}
                                    {pickup.delivery_status.id === 5 && (
                                        <div className="text-center mt-4">
                                            <button
                                                className="btn btn-primary"
                                                onClick={() => {
                                                    axios
                                                        .get(`${API_URL}/api/admin/pickups/${pickup.id}/invoice/pdf`, {
                                                            headers: { Authorization: `Bearer ${token}` },
                                                        })
                                                        .then((response) => {
                                                            const { data } = response;
                                                            if (data?.invoice_pdf) {
                                                                // Open the invoice PDF URL in a new tab
                                                                window.open(data.invoice_pdf, '_blank');
                                                            } else {
                                                                alert('Invoice not found for this pickup.');
                                                            }
                                                        })
                                                        .catch((error) => {
                                                            if (error.response?.status === 404) {
                                                                alert('No invoice found for this pickup.');
                                                            } else {
                                                                console.error('Error fetching the invoice URL:', error);
                                                                alert('Failed to fetch the invoice. Please try again later.');
                                                            }
                                                        });
                                                }}
                                            >
                                                Download Invoice
                                            </button>
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <GoogleMapsProvider>
                                <NPMapViewDirections
                                    origins={pickup.origins}
                                    dropoffs={pickup.dropoffs}
                                />
                            </GoogleMapsProvider>
                            <p className="mt-2">
                                Origin: <b>{pickup.origin_address}</b>
                                <br/>
                                Dropoff: <b>{pickup.dropoff_address}</b>
                                <br/>
                                Distance: <b>{pickup.distance} km</b>
                            </p>
                            <div className="card my-2">
                                <div className="card-body">
                                    <h5 className="text-center">Timestamps</h5>
                                    <div className="table-responsive">
                                        <table className="table table-sm table-bordered text-center mb-0">
                                            <tbody>
                                            {pickup.scheduled_pickup_time && (
                                                <tr className="table-info">
                                                    <td>Scheduled Pickup Time</td>
                                                    <td>{moment(pickup.scheduled_pickup_time).format("YYYY-MM-DD HH:mm:ss")}</td>
                                                </tr>
                                            )}
                                            {pickup.created_at && (
                                                <tr>
                                                    <td>Created At</td>
                                                    <td>{moment(pickup.created_at).format("YYYY-MM-DD HH:mm:ss")}</td>
                                                </tr>
                                            )}
                                            {pickup.waiting_for_driver_at && (
                                                <tr>
                                                    <td>Waiting for Driver At</td>
                                                    <td>{moment(pickup.waiting_for_driver_at).format("YYYY-MM-DD HH:mm:ss")}</td>
                                                </tr>
                                            )}
                                            {pickup.claimed_at && (
                                                <tr>
                                                    <td>Claimed At</td>
                                                    <td>{moment(pickup.claimed_at).format("YYYY-MM-DD HH:mm:ss")}</td>
                                                </tr>
                                            )}
                                            {pickup.started_at && (
                                                <tr>
                                                    <td>Started At</td>
                                                    <td>{moment(pickup.started_at).format("YYYY-MM-DD HH:mm:ss")}</td>
                                                </tr>
                                            )}

                                            {trackers && trackers.map(tracker => (
                                                <tr key={tracker.id}>
                                                    <td>
                                                        {tracker.action_title} <b>{tracker.origin_items && tracker.origin_items.join(', ')} {tracker.dropoff_items && tracker.dropoff_items.join(', ')}</b>
                                                        {tracker.origin &&
                                                        <>
                                                            <br/>
                                                            <small>{tracker.origin.address}</small>
                                                        </>
                                                        }
                                                        {tracker.dropoff &&
                                                        <>
                                                            <br/>
                                                            <small>{tracker.dropoff.address}</small>
                                                        </>
                                                        }
                                                    </td>
                                                    <td>
                                                        {tracker.completed
                                                            ? moment(tracker.updated_at).format("YYYY-MM-DD HH:mm:ss")
                                                            : '---'
                                                        }
                                                    </td>
                                                </tr>
                                            ))}

                                            {pickup.completed_at && (
                                                <tr>
                                                    <td>Completed At</td>
                                                    <td>{moment(pickup.completed_at).format("YYYY-MM-DD HH:mm:ss")}</td>
                                                </tr>
                                            )}
                                            {pickup.canceled_at && (
                                                <tr>
                                                    <td>Cancelled At</td>
                                                    <td>{moment(pickup.canceled_at).format("YYYY-MM-DD HH:mm:ss")}</td>
                                                </tr>
                                            )}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="mt-2">
                        <div className="card mb-2">
                            <div className="card-header">
                                <h5 className="mb-0 text-center">Items</h5>
                            </div>
                            <div className="card-body">
                                <div className="table-responsive">
                                    <table className='table table-sm table-striped text-center mb-0'>
                                        <thead>
                                        <tr>
                                            <th>ID</th>
                                            <th>Photo</th>
                                            <th>Name / Details</th>
                                            <th>Weight / Size</th>
                                            <th>Pickup Address</th>
                                            <th>Dropoff Address</th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        {pickup.items.map(item => (
                                            <tr key={item.id}>
                                                <td>{item.id}</td>
                                                <td>{item.photo && <a href={item.photo} target="_blank" rel="noopener noreferrer"><img src={item.photo} alt="Item" width="50"/></a>}</td>
                                                <td>{item.item_name}<br/>{item.details}</td>
                                                <td>{item.weight}<br/>{item.size}</td>
                                                <td>
                                                    {item.origin && (
                                                        <>
                                                            <b>{item.origin.address}</b>
                                                            <br />
                                                            {item.origin.contact_name && (
                                                                <>
                                                                    {item.origin.contact_name}
                                                                    <br />
                                                                </>
                                                            )}
                                                            {item.origin.contact_phone && (
                                                                <>

                                                                    {formatPhoneNumberWithMask(item.origin.contact_phone || "",
                                                                        "+9 999 999-9999")}
                                                                    <br />
                                                                </>
                                                            )}
                                                            <div className="card p-2" align="center">
                                                                <small>Secured Photo</small> <br/> {item.origin.photo_url
                                                                ?
                                                                <a href={item.origin.photo_url} target="_blank" rel="noopener noreferrer">
                                                                    <img src={item.origin.photo_url} alt="Secured Item Photo" width="100"/>
                                                                </a>
                                                                : 'Not Uploaded'}
                                                            </div>
                                                        </>
                                                    )}
                                                </td>
                                                <td>
                                                    {item.dropoff && (
                                                        <>
                                                            <b>{item.dropoff.address}</b>
                                                            <br />
                                                            {item.dropoff.contact_name && (
                                                                <>
                                                                    {item.dropoff.contact_name}
                                                                    <br />
                                                                </>
                                                            )}
                                                            {item.dropoff.contact_phone && (
                                                                <>

                                                                    {formatPhoneNumberWithMask(item.dropoff.contact_phone || "",
                                                                        "+9 999 999-9999")}
                                                                    <br />
                                                                </>
                                                            )}
                                                            <div className="card p-2" align="center">
                                                                <small>Complete Photo</small> <br/> {item.dropoff.photo_url
                                                                ?
                                                                <a href={item.dropoff.photo_url} target="_blank" rel="noopener noreferrer">
                                                                    <img src={item.dropoff.photo_url} alt="Complete Item Photo" width="100"/>
                                                                </a>
                                                                : 'Not Uploaded'}
                                                            </div>
                                                        </>
                                                    )}
                                                </td>
                                            </tr>
                                        ))}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>

                    {/* Feedbacks Section */}
                    {pickup.feedbacks && (
                        <div className="mt-4">
                            <h5 className="text-center">Feedbacks</h5>
                            <div className="row">
                                {/* Feedback from Customer */}
                                <div className="col-12 col-lg-6 mb-4">
                                    <div className="card">
                                        <div className="card-body">
                                            <h6>
                                                Feedback from Customer&nbsp;
                                                {pickup.user && (
                                                    <Link to={`/users/${pickup.user.id}`}>
                                                        <b>
                                                            {pickup.user.id} {pickup.user.full_name}
                                                        </b>
                                                    </Link>
                                                )}
                                            </h6>
                                            {pickup.feedbacks.user && pickup.feedbacks.user.rating_by === 1 ? (
                                                <>
                                                    <p>
                                                        Rating:{' '}
                                                        {renderStars(
                                                            parseFloat(pickup.feedbacks.user.rate) || 0
                                                        )}
                                                    </p>
                                                    <p>Feedback: {pickup.feedbacks.user.feedback}</p>
                                                </>
                                            ) : (
                                                <p>No feedback has been left.</p>
                                            )}
                                        </div>
                                    </div>
                                </div>

                                {/* Feedback from Driver */}
                                <div className="col-12 col-lg-6 mb-4">
                                    <div className="card">
                                        <div className="card-body">
                                            <h6>
                                                Feedback from Driver&nbsp;
                                                {pickup.driver && (
                                                    <Link to={`/drivers/${pickup.driver.id}`}>
                                                        <b>
                                                            {pickup.driver.id} {pickup.driver.name}
                                                        </b>
                                                    </Link>
                                                )}
                                            </h6>
                                            {pickup.feedbacks.driver && pickup.feedbacks.driver.rating_by === 2 ? (
                                                <>
                                                    <p>
                                                        Rating:{' '}
                                                        {renderStars(
                                                            parseFloat(pickup.feedbacks.driver.rate) || 0
                                                        )}
                                                    </p>
                                                    <p>Feedback: {pickup.feedbacks.driver.feedback}</p>
                                                </>
                                            ) : (
                                                <p>No feedback has been left.</p>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}
                </div>
            ) : (
                <div className="d-flex justify-content-center align-items-center" style={{ height: '80vh' }}>
                    <div className="spinner-border" role="status">
                        <span className="sr-only">Loading...</span>
                    </div>
                </div>
            )}

            <style jsx>{`
                .table td {
                    vertical-align: middle;
                }
                .duplicate-pickup-wrap {
                    position: absolute; 
                    top: 50%;
                    left: 100%;
                    transform: translate(-100%, -55%);
                }
                @media only screen and (max-width: 768px) {
                    .duplicate-pickup-wrap {
                        position: static;
                        transform: translateX(0);
                    }
                    .duplicate-pickup-btn {
                        padding: .25rem .5rem;
                        font-size: .875rem;
                        line-height: 1.5;
                        border-radius: .2rem;
                    }
                }
            `}</style>
        </div>
    );
};

export default withAuth(PickupDetails);
